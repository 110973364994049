import React from "react";
import ReactHtmlParser from "react-html-parser";
import {
  FaFacebookF,
  FaRedditAlien,
  FaTumblr,
  FaTwitter,
} from "react-icons/fa";
import {
  FacebookShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
} from "react-share";
import styled, { css } from "../lib/styled";
import { ICategory } from "../types/wordpressPost";
import * as _ from "lodash";
import { Link } from "gatsby";

interface Props {
  slug: string;
  title: string;
  excerpt: string;
  categories?: ICategory[];
}
const SharingBox: React.FC<Props> = (props) => {
  const shareUrl = `https://jurassicoutpost.com/${props.slug}`;
  return (
    <Wrapper>
      <ShareTitle>Share this article</ShareTitle>
      <SharingIcons>
        <FacebookShareButton
          quote={ReactHtmlParser(props.excerpt.replace(/<[^>]*>?/gm, ""))}
          url={shareUrl}
        >
          <ShareIcon color="#3b5998">
            <FaFacebookF />
          </ShareIcon>
        </FacebookShareButton>
        <TwitterShareButton title={ReactHtmlParser(props.title)} url={shareUrl}>
          <ShareIcon color="#00acee">
            <FaTwitter />
          </ShareIcon>
        </TwitterShareButton>
        <RedditShareButton title={ReactHtmlParser(props.title)} url={shareUrl}>
          <ShareIcon color="#ff4301">
            <FaRedditAlien />
          </ShareIcon>
        </RedditShareButton>
        <TumblrShareButton title={ReactHtmlParser(props.title)} url={shareUrl}>
          <ShareIcon color="#34526f">
            <FaTumblr />
          </ShareIcon>
        </TumblrShareButton>
      </SharingIcons>
      {props.categories && (
        <CategoriesWrapper>
          <ShareTitle>Categories</ShareTitle>
          <CategoryLinks>
            {props.categories &&
              props.categories.map((category, i) => {
                return (
                  <Link key={i} to={`/category/${category.slug}`}>
                    {ReactHtmlParser(category.name)}
                  </Link>
                );
              })}
          </CategoryLinks>
        </CategoriesWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.lightGrey};
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
  padding: ${({ theme }) => theme.sizes.spacing.s};
`;

const ShareTitle = styled.p`
  ${({ theme }) => css`
    color: ${({ theme }) => theme.color.darkOne};
    letter-spacing: 1.4px;
    font-size: 14px;
    font-family: ${theme.font.family.regular};
    font-weight: ${theme.font.weight.regular};
    text-transform: uppercase;
    margin-bottom: 10px;
  `}
`;
const SharingIcons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const CategoriesWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: ${theme.sizes.spacing.s};
    padding-bottom: 0;
    font-size: 16px;
    color: ${theme.color.primaryDark};
  `}
`;

const CategoryLinks = styled.div`
  ${({ theme }) => css`
    a {
      font-size: 14px;
      font-family: "Roboto";
      color: ${theme.color.darkOne};

      &:hover {
        color: ${theme.color.accent};
      }
      &:after {
        content: ", ";
      }
      &:last-child:after {
        content: "";
      }
    }
  `}
`;

const ShareIcon = styled.div<{ color: string }>`
  width: calc(33% - 12px);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  color: white;
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin: 6px;
`;

export default SharingBox;
